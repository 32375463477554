$column: 62rem;

@mixin spacing() {
  h2,
  h1 {
    margin-bottom: 1.1rem;
  }
  p:not(:last-child),
  h4:not(:last-child) {
    margin-bottom: 3.3rem;
  }
}

@mixin p() {
  p:not(:first-of-type) {
    margin-top: 2rem;
  }
}

@mixin mini() {
  font-size: 1.4rem;
}

@mixin textLink() {
  a {
    display: inline;
    font-weight: 900;
    transition: none;
    &,
    &:visited {
      color: $orange;
    }
    &:hover,
    &:active,
    &:focus {
      color: $orange;
    }
    &:hover {
      background: linear-gradient(
        180deg,
        transparent,
        transparentize($orange, 0.8) 85%,
        $orange 3px
      );
      background-size: 140% 30%;
      background-position-y: bottom;
      background-repeat: no-repeat;
    }
  }
}

@mixin darkTextLink() {
  a {
    display: inline;
    font-weight: 700;
    &,
    &:visited {
      color: $light;
    }
    &:hover,
    &:active,
    &:focus {
      color: $lightBlue;
    }
    &:hover {
      background: linear-gradient(
        180deg,
        transparent,
        transparentize($lightBlue, 0.7) 85%,
        $lightBlue 3px
      );
      background-size: 140% 30%;
      background-position-y: bottom;
      background-repeat: no-repeat;
    }
  }
}
